<div class="container">
  <header class="header-wrapper">
    <div class="header">
      <div class="logo" routerLink="/home">
        <img class="siem" src="assets/logo.svg">
      </div>
      <div class="route-crumps">
        <ng-container *ngFor="let crump of crumpService.route_crumps; index as i; let lindex = last">
          <ng-container>
            <p [ngClass]="lindex?'':'route-clickable'" (click)="crump_click(crump,i)">{{crump.disp_str}}</p>
            <p *ngIf="!lindex"> > </p>
          </ng-container>
        </ng-container>
      </div>
      <div class="menu-user" #userMenuOrigin>
        <img (click)="openOverlay_click(userMenuRef,userMenuOrigin)" class="profile" src="assets/userprofile.svg">
        <img class="menu-icon" src="assets/bell.svg">
        <img class="menu-icon" src="assets/apps.svg">
      </div>
    </div>

  </header>
  <div class="body-wrapper">
    <ng-container *ngIf="mat_spinner_userProfile_state">
      <div class="body-bottom-spinner">
        <mat-spinner class="spinner" diameter=20></mat-spinner>
      </div>
    </ng-container>
    <div class="body-wrapper-navbar">
      <div class="navbar-icon-container">
        <img [src]="homeIcon.img_url" [title]="homeIcon.label" (click)="navIconclick(homeIcon)" class="module-active">
      </div>
      <span class="divider"></span>
      <div class="navbar-icon-container">
        <ng-container *ngFor="let nav of crumpService.applist[0]">
          <img [ngClass]="nav.isSelectable ? 'module-active' :'module-inactive'" [src]="nav.img_url" [title]="nav.label"
            (click)="navIconclick(nav)">
        </ng-container>
      </div>
      <span class="divider"></span>
      <div class="navbar-icon-container">
        <ng-container *ngFor="let nav of crumpService.applist[1]">
          <img [ngClass]="nav.isSelectable ? 'module-active' :'module-inactive'" [src]="nav.img_url" [title]="nav.label"
            (click)="navIconclick(nav)">
        </ng-container>
      </div>
      <span class="divider"></span>
      <div class="navbar-icon-container">
        <ng-container *ngFor="let nav of crumpService.applist[2]">
          <img [ngClass]="nav.isSelectable ? 'module-active' :'module-inactive'" [src]="nav.img_url" [title]="nav.label"
            (click)="navIconclick(nav)">
        </ng-container>
      </div>
    </div>
    <div class="body-wrapper-content">
      <router-outlet></router-outlet>
    </div>

    <ng-template #userMenuRef>
      <div class="user-menu-container">
        <div class="user-menu-wrapper">
          <div>
          </div>
          <div class="row-1 menu" (click)="openMyProfile_click()">
            <i class="my-profile"></i><span>My Profile</span>
          </div>
          <div class="row-2 menu" (click)="openResetPassword_click()">
            <i class="reset-password"></i><span>Reset Password</span>
          </div>
          <div class="row-3 menu" (click)="logout()">
            <i class="logout"></i><span>Logout</span>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <footer class="footer-wrapper">
    <p class="copyright">&copy;{{footer?.copyright}}</p>
    <p class="version">{{footer?.version}}</p>
  </footer>
</div>