import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginComponent } from './user-login/user-login.component';
import { AuthenticationGuard } from './authentication.guard';
import { HomeComponent } from './home/home.component';
import { UserLandingComponent } from './settings/user-landing/user-landing.component';
import { RoleLandingComponent } from './settings/role-landing/role-landing.component';

const routes: Routes = [
  { path: 'login', component: UserLoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthenticationGuard] },
  { path: 'designer', loadChildren: () => import('./designer/designer.module').then(m => m.DesignerModule), canActivate: [AuthenticationGuard] },
  { path: 'rule', loadChildren: () => import('./rule/rule.module').then(m => m.RuleModule), canActivate: [AuthenticationGuard] },
  //{ path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthenticationGuard] },
  { path: 'user', component: UserLandingComponent },
  { path: 'role', component: RoleLandingComponent },
  { path: 'explorelogs', loadChildren: () => import('./explore-logs/explore-logs.module').then(m => m.ExploreLogsModule) },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }