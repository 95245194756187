import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserLoginService } from 'src/app/user-login/user-login.service';
import { VerifyUser } from '../validators/userDetails.validator';
import { VerifyUserName } from '../../shared/validators/username.validator'
import { VerifyFullName } from '../validators/fullname.validators';
import { UsernameValidator } from '../../shared/validators/usernameasync.validator';
import { SettingsService } from '../settings.service';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterViewInit,OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private ref:MatDialogRef<UserComponent>,
    private fb:UntypedFormBuilder,
    private loginService:UserLoginService,
    private settingservice:SettingsService
  ) { }

  usermeta = this.loginService.metaData.settings.createuserData;
  validationData = this.loginService.metaData.commonData.userDetailvalidationData
  submitState:boolean = false;
  timezoneoptions: any = this.loginService.metaData.commonData.timezoneList;
  inputBoxEmitChar =  this.loginService.metaData.commonData.specialCharList.charList;
  emailchar =  this.loginService.metaData.commonData.specialCharList.emailCharList;
  numCharList = this.loginService.metaData.commonData.specialCharList.numCharList;
  mat_spinner_user_state:boolean
  $destroy:Subject<any> = new Subject()

  userDetail = this.fb.group({
    username:[null,[Validators.required, VerifyUserName(),Validators.maxLength(this.validationData.username.maxlength),Validators.minLength(this.validationData.username.minlength)],[UsernameValidator.createValidator(this.settingservice)]],
    password:[null,[Validators.required,Validators.minLength(this.validationData.password.minlength),Validators.maxLength(this.validationData.password.maxlength)]],
    firstname:[null,[Validators.required,VerifyFullName(),Validators.maxLength(60)]],
    lastname:['',[VerifyFullName(),Validators.maxLength(60)]],
    mobile:[null,[Validators.required,Validators.pattern("^[0-9]{10}$")]],
    email:[null,[Validators.required, Validators.email, Validators.maxLength(this.validationData.email.maxlength)]],
    role:[null,[Validators.required]],
    timezone:["UTC",[Validators.required]],
    authenticationType:[null,[Validators.required]],
    authenticationUrl:[null],
    active:[null],
    block:[null],
    forcepwd:[null],
    fromTime:[null],
    toTime:[null],
    passwordChanged:[false],
    clearBlockedInterval:[false]
    
  },{validators:VerifyUser()})

  ngOnInit() {
    this.timezone.disable();
    this.mat_spinner_user_state=true
    this.authenticationType.valueChanges
    .pipe(
    startWith(this.authenticationType.value),
    takeUntil(this.$destroy))
    .subscribe((val)=>{
      let index = this.usermeta.formmsg.authtypeurl.typefilterOptions.indexOf(val)
      if(index>=0){
        this.authenticationUrl.setValue(this.usermeta.formmsg.authtypeurl.urlfilterOptions[index])
      }
    })
    if(this.data.editState){
      this.userDetail.addControl('pendingemail',this.fb.control(this.data.editdata.pendingemail))
      this.userDetail.addControl('pendingmobile', this.fb.control(this.data.editdata.pendingmobile))
      const editdata = this.mobileDataChange(this.data.editdata,0);
      this.password.disable();
      this.username.disable();
      this.userDetail.patchValue(editdata)
    }else{
      this.userDetail.patchValue(this.usermeta.defaultValues)
    }
    if(!this.data.matspin){
      this.mat_spinner_user_state=false
    }
  }

  ngAfterViewInit(): void {
    this.authenticationUrl.disable()
  }

  get username(){
    return this.userDetail.get('username')
  }

  get password(){
    return this.userDetail.get('password')
  }

  get firstname(){
    return this.userDetail.get('firstname')
  }

  get lastname(){
    return this.userDetail.get('lastname')
  }

  get mobile(){
    return this.userDetail.get('mobile')
  }

  get email(){
    return this.userDetail.get('email')
  }
  
  get role(){
    return this.userDetail.get('role')
  }

  get timezone(){
    return this.userDetail.get('timezone')
  }

  get authenticationType(){
    return this.userDetail.get('authenticationType')
  }

  get authenticationUrl(){
    return this.userDetail.get('authenticationUrl')
  }

  get pendingmobile() {
    return this.userDetail.get('pendingmobile')
  }

  get pendingemail(){
    return this.userDetail.get('pendingemail')
  }

  get passwordChanged(){
    return this.userDetail.get('passwordChanged')
  }

  get fromTime(){
    return this.userDetail.get('fromTime')
  }

  get toTime(){
    return this.userDetail.get('toTime')
  }

  get clearBlockedInterval(){
    return this.userDetail.get('clearBlockedInterval')
  }

  editPasswordClick(){
    this.password.enable()
    this.passwordChanged.setValue(true)
    this.password.reset()
  }

  mobileApproveClick(val:number){
    if(val === -1){
      this.mobile.setValue(this.data.editdata.oldmobile)
    }
    this.pendingmobile.setValue(val)
  }

  emailApproveClick(val:number){
    if(val === -1){
      this.email.setValue(this.data.editdata.oldemail)
    }
    this.pendingemail.setValue(val)
  }

  closedialogClick(){
    this.ref.close()
  }

  mobileDataChange(val:any,state:number){
    if(state){
      Number(val.mobile)
    }else{
      val.mobile.toString()
    }
    return val
  }

  checkRoleChanged(postval:any){
    if(this.data.editdata.role.toString() !== this.role.value.toString()){
      postval["rolechanged"] = true;
    }else {
      postval["rolechanged"] = false;
    }

    if(this.data.editdata.timezone.toString() !== this.timezone.value.toString()){
      postval["timezonechanged"] = true;
    }else {
      postval["timezonechanged"] = false;
    }

    return postval;
  }

 

  clearInterval(formvalue){
    if(formvalue.clearBlockedInterval){
      formvalue['fromTime'] = null;
      formvalue['toTime'] = null;
    }
    return formvalue;
  }

  submitUser(){

    this.submitState = true;
    if(this.userDetail.valid){
      this.mat_spinner_user_state = true
      let userdetail = this.mobileDataChange(this.userDetail.getRawValue(),1)
      userdetail = this.clearInterval(userdetail)

      if(this.data.editState){
        userdetail['oldmobile'] = this.data.editdata.oldmobile;
        userdetail['oldemail'] = this.data.editdata.oldemail;
        userdetail = this.checkRoleChanged(userdetail);
        this.settingservice.postEditUser(userdetail)
        .pipe(takeUntil(this.$destroy))
        .subscribe((val:any)=>{
          this.mat_spinner_user_state = false
          this.submitState = false;
          this.ref.close(val);
          this.settingservice.showToaster(this.usermeta.toastmsg.changes,'success')
        },
        (err)=> {
          this.mat_spinner_user_state = false;
        })
      }else{
          
          this.settingservice.postNewUser(userdetail)
          .pipe(takeUntil(this.$destroy))
          .subscribe((val:any)=>{
            this.mat_spinner_user_state = false
          this.submitState = false;
          this.ref.close(val)
          this.settingservice.showToaster(this.usermeta.toastmsg.success,'success')
        },
        (err)=> {
          this.mat_spinner_user_state = false;
        })
      }
    }
  }
  ngOnDestroy(): void {
    this.$destroy.next()
  }
}
