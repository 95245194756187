import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CdkOverlyayService } from 'src/app/shared/custom-overlay/cdk-overlyay.service';
import { TemplateDropdownComponent } from 'src/app/shared/custom-overlay/template-dropdown/template-dropdown.component';
import { FILE_PREVIEW_DIALOG_DATA } from 'src/app/shared/custom-overlay/cdk-overlay-injector';
import { cdkOverlayRef } from 'src/app/shared/custom-overlay/cdk-overlay-ref';
import { AssetDropdownService } from 'src/app/shared/assets/assets-dropdown.service';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})

export class AssetsComponent implements OnInit, AfterViewInit, OnDestroy {
  //to hold the dropdowns metadata
  assetsMetaData = this.assetsService.loadmetaData().common_top_bar;
  assetslabels = this.assetsService.loadlabels();
  platformdata = this.assetsService.loadplatformdata();
  additionalplatformref: any;
  // form group for the available hosts in each assets
  assets = this.fb.group({
    platform: [''],
    selHosts: this.fb.group({}),
    searchValue: [''],
    toggleControl: [false],
    totalHosts: this.fb.group({}),
  })
  $destroy: Subject<boolean> = new Subject();
  searchHost = new UntypedFormControl('');// to search among hosts
  //initial variables
  totalAssets: any;
  tagsStructure: any = {};
  hostsArray: any;
  hostsSelectedbyTag = [];
  selectedTagsArray = new Set();
  tagsCategoriesObj = {};
  selectedHostsArray = [];
  uniqueHostsList = new Set();
  platformfields = [];
  tagsHost = [];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.overLayRef.close();
    }
  }

  shownohostmsg: boolean = false;
  filteredHostArray = []

  @ViewChildren('hosts') hostlist: QueryList<ElementRef>
  @ViewChild('platform_list') platform_list:ElementRef<HTMLElement>;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any,
    private overLayRef: cdkOverlayRef,
    private dropdown: CdkOverlyayService,
    public assetsService: AssetDropdownService,
  ) { }

  ngOnInit() {
    this.initilizeData(this.data[0].platform);
    this.selHosts.disable({ emitEvent: false })
    this.searchValue.valueChanges.subscribe((searchstr) => {
      if (this.toggleControl.value) {
        this.mappingTags(this.totalAssets.selectedCategories)
        this.tagsObjBuild(searchstr)
        this.shownohostmsg = !this.shownohostmsg
      } else {
        this.filteredHostArray = [
          ...this.hostsArray.filter((item: any) => {
            return item['disp'].toLowerCase().includes(searchstr.toLowerCase())
          }),
        ]
      }
    })

    if (this.data[1].disable) {
      this.platform.disable()
    }
  }
  ngAfterViewInit(): void { }

  //alias for the form array
  get toggleControl() {
    return this.assets.get('toggleControl')
  }

  get platform() {
    return this.assets.get('platform')
  }

  get searchValue() {
    return this.assets.get('searchValue')
  }

  get totalHosts() {
    return this.assets.get('totalHosts') as UntypedFormGroup;
  }
  get totalTags() {
    return this.assets.get('totalTags') as FormGroup
  }
  get departments() {
    return this.totalTags.get('departments')
  }
  get locations() {
    return this.totalTags.get('locations')
  }
  get technical() {
    return this.totalTags.get('technical')
  }
  get selHosts() {
    return this.assets.get('selHosts') as FormGroup;
  }

  async initilizeData(platform: string) {
    if (this.assetsService.platformSelected.value !== platform) {
      this.platform.setValue(platform, { emitEvent: false });
      await this.fetchHostTagsdata(platform)
        .then((assets:any) => {
          this.assetsService.platformSelected.setValue(platform, { emitEvent: false });
          this.assetsService.assetshostdata = assets[0]['hosts'];
          this.assetsService.assetstagdata = assets[1];
          this.loadnewHostdata(assets[0].hosts, false);
          this.loadnewAssetdata(assets[1]);
          this.assets.addControl('totalTags', this.formGroupgen(this.tagsCategoriesObj));
          setTimeout(() => {
            this.setDefaultvalue();
          }, 0)
          this.assetsService.assetsSpinnerState = false;
          this.selHosts.enable({ emitEvent: false });
        })
    } else {
      this.platform.setValue(platform, { emitEvent: false });
      this.loadnewHostdata(this.assetsService.assetshostdata, false);
      this.loadnewAssetdata(this.assetsService.assetstagdata);
      this.assets.addControl('totalTags', this.formGroupgen(this.tagsCategoriesObj));
      setTimeout(() => {
        this.setDefaultvalue();
      }, 0)

      this.assetsService.assetsSpinnerState = false;
      this.selHosts.disable({ emitEvent: false });
    }
  }

  setDefaultvalue() {
    if (this.data[0]) {
      this.toggleControl.patchValue(this.data[0].toggleControl)
      this.data[0].selectedHosts.forEach((host) => {
        this.selectHost_click(host)
      })
      this.data[0].selectedTags.forEach((tag) => {
        for (const member of this.totalAssets.selectedMembers) {
          if (tag == member.disp) {
            this.initiliseTagsClick(member.category, member)
          }
        }
      })
      for (const tag in this.data[0].excludedHosts) {
        this.data[0].excludedHosts[tag].forEach(val => {
          this.getTagcontrol(tag).controls[val].patchValue(false);
          this.tagsHost.map(host => {
            if (host.member == tag && host.hostname == val) {
              host.selected = false;
            }
          })
        })
      }
    }
  }

  loadnewHostdata(host: any, defaultvalue: boolean) {
    this.hostsArray = host;
    this.hostsArray.forEach((host: any) => {
      this.totalHosts.addControl(host.value, new UntypedFormControl(defaultvalue));
    })
    this.filteredHostArray = [
      ...this.hostsArray.filter((item: any) => {
        return item['disp'].toLowerCase().includes(this.searchValue.value.toLowerCase());
      }),
    ]
  }

  loadnewAssetdata(asset: any) {
    this.totalAssets = asset;
    this.processTagsData(asset);
    this.mappingTags(this.totalAssets.selectedCategories);
    this.tagsObjBuild('');
  }

  //revamping datastructure to accessible form.
  processTagsData(data: any) {
    data.selectedCategories.forEach((elem: any) => {
      this.tagsStructure[elem.value] = {
        color: elem.color,
        members: {}
      }
    })
    data.selectedMembers.forEach((elem: any) => {
      this.tagsStructure[elem.category].members[elem.disp] = {
        hosts: []
      }
    })
    data.selectedHosts.forEach((host: any) => {
      data.selectedMembers.forEach((elem: any) => {
        if (host.member == elem.value)
          this.tagsStructure[elem.category].members[elem.disp].hosts.push(host)
      })
    })
  }
  //Object structure to create tags options dynamically  
  tagsObjBuild(search: any) {
    if (this.totalAssets.selectedMembers) {
      for (const category of this.totalAssets.selectedCategories) {
        for (const tags of this.totalAssets.selectedMembers) {
          const searchTerm: string = tags.value
          if (tags.category == category.value && searchTerm?.toLowerCase().includes(search.toLowerCase()))
            this.tagsCategoriesObj[category.value].push(tags)
        }
      }
    }
  }
  //to build empty array into the total tags Object
  mappingTags(tagsdata) {
    for (const tag of tagsdata) {
      this.tagsCategoriesObj[tag.value] = [];
    }
  }

  //converts any nested object into nested form group
  formGroupgen(dataObj: any) {
    let tagGroup = this.fb.group({})
    function getTags(tag) {
      return tagGroup.get(tag) as UntypedFormGroup
    }
    for (const tagKey in dataObj) {
      tagGroup.setControl(tagKey, this.fb.group({}));
      for (const tag of dataObj[tagKey]) {
        getTags(tagKey).setControl(tag.disp, new UntypedFormControl(false));
      }
    }
    return tagGroup
  }

  //to get hosts related to the platforms, when a platform is seleted
  selectPlatform_click(platform) {
    if (!this.assetsService.assetsSpinnerState && !this.data[1].disable) {
      this.processPlatformChange(platform.value);
    }
  }

  async loadnewplatform(platform: string) {
    await this.fetchHostTagsdata(platform)
      .then(assets => {
        this.platform.setValue(platform, { emitEvent: false });
        if (!this.data[1].oneHost) {
          this.loadnewHostdata(assets[0].hosts, true);
          for (let i = 0; i < assets[0].hosts.length; i++) {
            this.selectedHostsArray.push(assets[0].hosts[i].value);
          }
        } else {
          this.loadnewHostdata(assets[0].hosts, false);
          this.totalHosts.controls[assets[0].hosts[0].value].setValue(true);
          this.selectedHostsArray.push(assets[0].hosts[0].value);
        }

        this.loadnewAssetdata(assets[1]);
        this.assets.setControl('totalTags', this.formGroupgen(this.tagsCategoriesObj));
        this.assetsService.assetsSpinnerState = false;
        this.searchValue.enable({ emitEvent: false });
        this.toggleControl.enable({ emitEvent: false });
      })
      .catch(err => {
        this.assetsService.assetsSpinnerState = false;
        this.searchValue.enable({ emitEvent: false });
        this.toggleControl.enable({ emitEvent: false });
      })
  }

  async processPlatformChange(platform: string) {
    if (platform !== this.platform.value) {
      this.assetsService.assetsSpinnerState = true;
      this.searchValue.disable({ emitEvent: false });
      this.toggleControl.disable({ emitEvent: false });
      this.selectedHostsArray = [];
      this.tagsHost.length = 0;
      this.selectedTagsArray.clear();
      for (const tagGroup in this.selHosts?.controls) {
        this.selHosts.removeControl(tagGroup);
      }
      for (const tagGroup in this.totalTags.controls) {
        this.totalTags.removeControl(tagGroup);
      }
      for (const tagGroup in this.totalHosts.controls) {
        this.totalHosts.removeControl(tagGroup);
      }
      this.tagsStructure = {};
      this.tagsCategoriesObj = {};
      await this.fetchPlatformFields(platform)
        .then((platformfields: any) => {
          if (platformfields.platform_fields) {
            this.platformfields = platformfields.platform_fields;
          }
          this.loadnewplatform(platform);
        })
    }
  }

  fetchHostTagsdata(platform) {
    return forkJoin([
      this.assetsService.getHostsList({ 'platform': platform }),
      this.assetsService.getHostsCategories({ 'platform': platform })
    ]).toPromise()
  }

  fetchPlatformFields(platform: string) {
    return this.assetsService.getCommonPlatformFields({ 'platform': platform }).toPromise()
  }

  //sort the key value from the array of objects with original order
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  //to limit number of items in an array to be shown in screen
  limitNoOfItems(data: Array<object> | Set<any>, count: number) {
    if (data) {
      return [...data].slice(0, count)
    }
  }

  //submit the selected hosts to parent component
  formSubmit() {
    if (!this.assetsService.assetsSpinnerState) {
      let responseobj = {};
      responseobj['platform'] = this.platform.value;
      responseobj['toggleControl'] = this.toggleControl.value;
      responseobj['selectedTags'] = [];
      responseobj['selectedHosts'] = [];
      responseobj['excludedHosts'] = {};
      responseobj['platformfields'] = this.platformfields;
      for (const tags in this.totalTags.value) {
        for (const member in this.totalTags.get(tags).value) {
          if (this.totalTags.get(tags).get(member).value) {
            responseobj['selectedTags'].push(member)
          }
        }
      }
      for (const tag in this.selHosts.value) {
        responseobj['excludedHosts'][tag] = []
        this.uniqueHostsbyTag(tag);
        for (const host in this.selHosts.get(tag).value) {
          if (!this.getTagcontrol(tag).controls[host].value)
            responseobj['excludedHosts'][tag].push(host)
        }
      }

      for (const host in this.totalHosts.value) {
        if (this.totalHosts.controls[host].value) {
          responseobj['selectedHosts'].push(host);
          this.uniqueHostsList.add(host);
        }
      }
      responseobj['uniqueHostsLength'] = this.uniqueHostsList.size;
      if (this.data[1].uniqueHostsList) {
        responseobj['uniqueHostsList'] = Array.from(this.uniqueHostsList)
      }
      this.assetsService._savedAssets.next(responseobj)
      this.overLayRef.close()
    }
  }

  //to cancel the selection of hosts and close the dropdown
  formCancel() {
    if (!this.assetsService.assetsSpinnerState) {
      this.overLayRef.close()
    }
  }

  //to return color to the selected Tags.
  tagChipColor(tag: any) {
    for (const member of this.totalAssets.selectedCategories) {
      if (member.value == tag.category) {
        return { "background-color": member.color }
      }
    }
  }

  hostsByTags(category: any, member: any) {
    this.selHosts.addControl(member.value, this.fb.group({}));
    this.tagsStructure[category].members[member.disp].hosts.forEach(host => {
      const notExcluded = this.sortExcludedHost(host.value);
      this.getTagcontrol(member.value).addControl(host.value, this.fb.control(notExcluded));
      this.tagsHost.push({ parent: category, hostname: host.value, selected: notExcluded, member: member.value });
    })
  }

  sortExcludedHost(hostname:string){

    const excludedHost = this.tagsHost.filter((host)=>{
      if(!host.selected){
        return host;
      }
    })
    for(let i=0; i<excludedHost.length; i++){
      if(hostname == excludedHost[i].hostname){
        return false
      }
    }
    return true;
  }

  openMoreHosts_click(selectedHosts: any, origin: any) {
    let config = {
      data: { "template": selectedHosts }
    }
    this.additionalplatformref = this.dropdown.openMenu(config, TemplateDropdownComponent, origin)
  }

  //to check the checkbox if the list is clicked 
  selectHost_click(host) {
    if (!this.totalHosts.controls[host]?.value) {
      this.totalHosts.controls[host]?.patchValue(true)
      this.selectedHostsArray.push(host)
    } else {
      this.totalHosts.controls[host]?.patchValue(false)
      this.selectedHostsArray?.splice(this.selectedHostsArray.indexOf(host), 1)
    }
  }

  initiliseTagsClick(category: any, tag: any) {
    this.totalTags.get(category).get(tag.disp).patchValue(true);
    this.selHosts.addControl(tag.value, this.fb.group({}));
    this.tagsStructure[category].members[tag.disp].hosts.forEach(val => {
      this.getTagcontrol(tag.value).addControl(val.value, this.fb.control(true));
      this.tagsHost.push({ parent: category, hostname: val.value, selected: true, member: tag.value });
    })
    this.selectedTagsArray.add(tag);
  }

  selectTags_click(category: string, tag: any) {
    if (!this.totalTags.get(category).get(tag.disp).value) {
      this.totalTags.get(category).get(tag.disp).setValue(true, { emitEvent: false });
      this.hostsByTags(category, tag);
      this.selectedTagsArray.add(tag);
    } else {
      this.totalTags.get(category).get(tag.disp).setValue(false, { emitEvent: false });
      this.selectedTagsArray.delete(tag);
      this.selHosts.removeControl(tag.value);
      this.removeMember(tag.value);
    }
  }

  removeMember(member: string) {
    this.tagsHost = this.tagsHost.filter(host => {
      if (host.member !== member) {
        return host;
      }
    })
  }

  hostToggle_click(hostDetails: any) {
    const hostvalue = this.getTagcontrol(hostDetails.member).controls[hostDetails.hostname].value;
    this.checkOthergroupHosts(hostvalue, hostDetails.hostname);
    setTimeout(() => {
      this.tagsHost.map(host => {
        if (host.hostname === hostDetails.hostname) {
          host.selected = !hostvalue;
        }
      })
    })
  }

  uniqueHostsbyTag(tag: string) {
    for (const host in this.selHosts.get(tag).value) {
      if (this.getTagcontrol(tag).controls[host].value)
        this.uniqueHostsList.add(host)
    }
  }

  getTagcontrol(tag: string) {
    return this.selHosts.controls[tag] as FormGroup;
  }

  getHostfromTag(tag: string, host: string) {
    return this.getTagcontrol(tag).controls[host];
  }

  checkOthergroupHosts(hostvalue: boolean, hostname: string) {
    
    for (const tagGroup in this.selHosts.value) {
      for (const tagname in this.selHosts.value[tagGroup]) {
        if (hostname == tagname) {
          this.getHostfromTag(tagGroup, hostname).patchValue(!hostvalue, { emitEvent: false })
        }
      }
    }
  }

  clearBtn() {
    if (!this.assetsService.assetsSpinnerState) {
      for (const host in this.totalHosts.controls) {
        this.totalHosts.controls[host].patchValue(false);
      }
      this.selectedHostsArray = [];
      this.selectedTagsArray.clear();
      this.tagsHost.length = 0;
      for (const tag of this.totalAssets.selectedMembers) {
        this.totalTags.get(tag.category).get(tag.disp).patchValue(false)
      }
      for (const controls in this.selHosts.controls) {
        this.selHosts.removeControl(controls);
      }
    }
  }

  remainingItems(data: Array<object> | Set<any>, count: number) {
    if (data) {
      return [...data].slice(count)
    }
  }

  clearTagItem(tag: any) {
    this.totalTags.get(tag.category).get(tag.disp).patchValue(false);
    this.selectedTagsArray.delete(tag);
    this.selHosts.removeControl(tag.value);
    this.removeMember(tag.value);
    if (this.selectedTagsArray.size == 5 && this.additionalplatformref) {
      this.additionalplatformref.closeoverlay();
    }
  }

  moveplatformlist(direction:number){
    this.platform_list.nativeElement.scrollTo({left: direction*(this.platform_list.nativeElement.scrollLeft+100), behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.unsubscribe();
    this.overLayRef.close();
  }
}